<template>
  <div class="players">
    <div class="players__header">
      <Heading
        as="h2"
        size="xs"
      >
        Players
      </Heading>
      <PlayersTabs
        v-if="showTabs"
        v-model="selectedTab"
        :items="tabs"
      />
      <div
        v-if="isBaseball && showTabs"
        class="players__baseball-navigation"
      >
        <div
          :class="['players__baseball-navigation-tab', { 'selected': selectedPlayerType === 'pitchers' }]"
          @click="selectPlayerType('pitchers')"
        >
          Pitchers
        </div>
        <div
          :class="['players__baseball-navigation-tab', { 'selected': selectedPlayerType === 'hitters' }]"
          @click="selectPlayerType('hitters')"
        >
          Hitters
        </div>
      </div>
    </div>
    <div class="players__content">
      <Spinner
        v-if="isLoading"
        class="players__spinner"
      />
      <PlayersTable
        v-else
        :key="selectedTab"
        :columns="columns"
        :players="filteredPlayers"
      />
    </div>
  </div>
</template>

<script>
import {
  filter, concat, includes, find,
} from 'lodash';
import { ref, computed, onMounted } from 'vue';
import { useStore } from 'vuex';
import { getTeamSquad } from '@/services/api';
import {
  createPlayerColumns,
  createTeamOptions,
  mapTeams,
  mapSquad,
  mapStatistics,
  mapPositions,
  mapPlayers,
  sortPlayers,
  mapPlayerIdsFromPlayerProps,
} from '@/services/helpers/players';
import sportIds from '@/services/helpers/sports';
import Heading from '@/components/common/Heading';
import Spinner from '@/components/common/Spinner';
import PlayersTabs from './PlayersTabs';
import PlayersTable from './PlayersTable';

const { FOOTBALL_ID, BASEBALL_ID } = sportIds;

const FOOTBALL_PLAYER_POSITIONS = ['OFFENSIVE_NON_QB', 'QUARTERBACK', 'UNKNOWN'];

export default {
  components: {
    Heading,
    Spinner,
    PlayersTabs,
    PlayersTable,
  },
  props: {
    event: {
      type: Object,
      required: true,
    },
    playerProps: {
      type: Object,
      default: null,
    },
  },
  setup(props) {
    const store = useStore();

    const loading = ref(false);
    const homeSquad = ref([]);
    const awaySquad = ref([]);
    const teams = computed(() => mapTeams(props.event));
    const squad = computed(() => concat([], homeSquad.value, awaySquad.value));
    const allPlayers = computed(() => concat([], props.playerProps?.teamA?.playerProps, props.playerProps?.teamB?.playerProps));
    const homeLineup = computed(() => props.event.homeLineup ?? []);
    const awayLineup = computed(() => props.event.awayLineup ?? []);
    const lineup = computed(() => concat([], homeLineup.value, awayLineup.value));
    const statistics = computed(() => mapStatistics(lineup.value));
    const positions = computed(() => mapPositions(lineup.value));
    const isBaseball = computed(() => props.event.sportId === BASEBALL_ID);
    const selectedPlayerType = ref('pitchers');
    const selectPlayerType = (playerType) => {
      selectedPlayerType.value = playerType;
    };
    const tabs = computed(() => createTeamOptions(props.event));
    const selectedTab = ref('');

    const columns = computed(() => createPlayerColumns(props.event, {
      hideTeam: selectedTab.value,
      type: selectedPlayerType.value,
    }));

    const players = computed(() => {
      const mappedPlayers = mapPlayers({
        squad: squad.value,
        statistics: statistics.value,
        positions: positions.value,
      });
      const tradingPlayerIds = mapPlayerIdsFromPlayerProps({
        playerProps: props.playerProps,
      });
      const availablePlayers = filter(mappedPlayers, ({ id }) => includes(tradingPlayerIds, id));

      if (props.event.sportId !== FOOTBALL_ID) return availablePlayers;
      return filter(availablePlayers, ({ position }) => includes(FOOTBALL_PLAYER_POSITIONS, position));
    });

    const showTabs = computed(() => !loading.value && players.value.length);
    const filteredPlayers = computed(() => {
      let filteredPlayersBase = players.value;
      if (isBaseball.value) {
        filteredPlayersBase = filter(filteredPlayersBase, (player) => {
          switch (selectedPlayerType.value) {
          case 'pitchers':
            return !!player.stats?.pitcherDetails && find(allPlayers.value, { playerId: player.id })?.isPitcher;
          case 'hitters':
            return !!player.stats?.batterDetails && find(allPlayers.value, { playerId: player.id })?.isHitter;
          default:
            return false;
          }
        });
      }
      const sortPlayersOptions = {
        isUSAView: props.event.isUSAView,
        homeId: props.event.home.id,
        awayId: props.event.away.id,
      };

      if (!selectedTab.value) return sortPlayers(filteredPlayersBase, sortPlayersOptions);
      const playerFilter = { team: { id: selectedTab.value } };
      return sortPlayers(filter(filteredPlayersBase, playerFilter), sortPlayersOptions);
    });

    const loadPlayers = async () => {
      try {
        loading.value = true;

        const homeResponse = await getTeamSquad({
          teamId: props.event.home.id,
          sportId: props.event.sportId,
        });
        const awayResponse = await getTeamSquad({
          teamId: props.event.away.id,
          sportId: props.event.sportId,
        });

        homeSquad.value = mapSquad({
          teams: teams.value,
          squad: homeResponse?.squad ?? [],
        });
        awaySquad.value = mapSquad({
          teams: teams.value,
          squad: awayResponse?.squad ?? [],
        });
      } catch (error) {
        console.error(error);
        homeSquad.value = [];
        awaySquad.value = [];
      } finally {
        loading.value = false;
      }
    };

    onMounted(loadPlayers);

    const isLoading = computed(() => loading.value || store.getters.tradingEventLoading || store.getters.tradingGameParamsLoading || store.getters.playerParamsLoading);

    return {
      loading,
      showTabs,
      tabs,
      selectedTab,
      columns,
      squad,
      homeSquad,
      awaySquad,
      homeLineup,
      awayLineup,
      lineup,
      players,
      filteredPlayers,
      isBaseball,
      selectedPlayerType,
      selectPlayerType,
      isLoading,
    };
  },
};
</script>

<style lang="scss" scoped>
.players {
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.players__header {
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-bottom: 8px;
}

.players__title {
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  color: #191414;
}

.players__content {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.players__spinner {
}

.players__baseball-navigation {
  height: 33px;
  width: 100%;
  box-shadow: inset 0px -2px 0px #CDCDCD;
  display: flex;

  .players__baseball-navigation-tab {
    flex: 1;
    width: 100%;
    height: 100%;
    padding: 0 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    text-align: center;

    &.selected {
      box-shadow: inset 0px -2px 0px #003C3C;
    }
  }
}
</style>
