<template>
  <div class="statistics">
    <div class="statistics__header">
      <Heading
        as="h2"
        size="xs"
      >
        Event statistics
      </Heading>
      <StatisticsSelect
        v-if="dataSet"
        v-model:value="selectedPeriod"
        :items="availablePeriods"
      />
    </div>
    <StatisticsTable
      :configuration="configuration"
      :items="selectedStatistics"
      :reverse="isUsaView"
    />
  </div>
</template>

<script>
import { isFunction } from 'lodash';
import { ref, computed } from 'vue';
import { createDataSet, createStatistics } from '@/services/helpers/statistics';
import sportIds from '@/services/helpers/sports';
import Soccer from '@/services/helpers/soccer';
import Basketball from '@/services/helpers/basketball';
import Football from '@/services/helpers/football';
import Ultimate from '@/services/helpers/ultimate';
import Hockey from '@/services/helpers/hockey';
import Baseball from '@/services/helpers/baseball';
import Heading from '@/components/common/Heading';
import StatisticsSelect from './StatisticsSelect';
import StatisticsTable from './StatisticsTable';

const {
  BASKETBALL_ID,
  FOOTBALL_ID,
  SOCCER_ID,
  ULTIMATE_ID,
  HOCKEY_ID,
  BASEBALL_ID,
} = sportIds;

export default {
  components: {
    Heading,
    StatisticsSelect,
    StatisticsTable,
  },
  props: {
    event: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const sportUtils = computed(() => {
      switch (props.event.sportId) {
      case SOCCER_ID:
        return Soccer;
      case BASKETBALL_ID:
        return Basketball;
      case FOOTBALL_ID:
        return Football;
      case ULTIMATE_ID:
        return Ultimate;
      case HOCKEY_ID:
        return Hockey;
      case BASEBALL_ID:
        return Baseball;
      default:
        return null;
      }
    });
    const isUsaView = computed(() => props.event.isUSAView);

    const availablePeriods = computed(() => sportUtils.value.getAvailablePeriods(props.event));
    const selectedPeriod = ref(availablePeriods?.value?.[0]?.id ?? '');
    const configuration = computed(() => {
      const { createStatisticsConfiguration } = sportUtils.value ?? {};
      if (!isFunction(createStatisticsConfiguration)) return null;
      return createStatisticsConfiguration(props.event);
    });
    const dataSet = computed(() => configuration.value && createDataSet(props.event, configuration.value));
    const statistics = computed(() => dataSet.value && createStatistics(dataSet.value));
    const selectedStatistics = computed(() => statistics.value?.[selectedPeriod.value]);

    return {
      isUsaView,
      selectedPeriod,
      availablePeriods,
      configuration,
      dataSet,
      statistics,
      selectedStatistics,
    };
  },
};
</script>

<style lang="scss" scoped>
.statistics {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.statistics__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
}

.statistics__title {
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  color: #191414;
}
</style>
