<template>
  <div
    v-if="hasData"
    class="statistics-table"
  >
    <div class="statistics-table__content">
      <div
        :class="[
          'statistics-table__row',
          'statistics-table__row--header',
        ]"
      >
        <div class="statistics-table__cell">
          Name
        </div>
        <div
          v-for="team in teams"
          :key="team.field"
          class="statistics-table__cell"
        >
          {{ team.label }}
        </div>
      </div>
      <div
        v-for="(label, type) in configuration?.labels"
        :key="type"
        :class="[
          'statistics-table__row',
          'statistics-table__row--data',
        ]"
      >
        <div class="statistics-table__cell">
          {{ label }}
        </div>
        <div
          v-for="team in teams"
          :key="team.field"
          class="statistics-table__cell"
        >
          {{ formatValue(type, getData(type)[team.field]) }}
        </div>
      </div>
    </div>
  </div>
  <div
    v-else
    class="statistics-table"
  >
    <div class="statistics-table__message">
      No data available.
    </div>
  </div>
</template>

<script>
import { orderBy, isFinite, isFunction } from 'lodash';
import { computed } from 'vue';

export default {
  props: {
    configuration: {
      type: Object,
      default: null,
    },
    items: {
      type: [Object, null],
      default: null,
    },
    reverse: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const teams = computed(() => orderBy([
      {
        label: 'Away',
        field: 'awayValue',
        order: props.reverse ? 0 : 1,
      },
      {
        label: 'Home',
        field: 'homeValue',
        order: props.reverse ? 1 : 0,
      },
    ], 'order'));
    const hasData = computed(() => props.configuration?.labels && props.items);
    const getData = (type) => props.items[type];
    const formatValue = (type, value) => {
      const typeFormatter = props.configuration?.formatters?.[type];
      if (isFunction(typeFormatter)) return typeFormatter(value);
      return isFinite(value) ? value : '-';
    };

    return {
      teams,
      hasData,
      getData,
      formatValue,
    };
  },
};
</script>

<style lang="scss" scoped>
.statistics-table {
  width: 100%;
  height: 100%;
  overflow: auto;
}

.statistics-table__content {
  display: table;
  border-collapse: collapse;
  position: relative;
  width: 100%;
}

.statistics-table__row {
  display: table-row;
  width: 100%;
  height: 33px;

  &.statistics-table__row--header {
    background-color: #fafafa;
    color: #6D6D6D;

    .statistics-table__cell {
      position: sticky;
      top: 0;
      z-index: 1;

      &:first-of-type {
        left: 0;
        z-index: 2;
      }
    }
  }

  &.statistics-table__row--data {
    background-color: #fff;
    color: #191414;
    border-bottom: 1px solid #F0F0F0;

    .statistics-table__cell:first-of-type {
      position: sticky;
      left: 0;
    }
  }
}

.statistics-table__cell {
  background-color: inherit;
  color: inherit;
  display: table-cell;
  vertical-align: middle;
  height: 100%;
  min-width: 50px;
  padding: 8px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;

  &:not(:first-of-type) {
    text-align: right;
  }
}

.statistics-table__message {
  font-family: Rubik;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  color: #A9A9A9;
  text-align: center;
  width: 100%;
}
</style>
