<template>
  <div
    :class="[
      'scoreboard',
      { 'scoreboard--reverse': isUsaView },
    ]"
  >
    <div class="scoreboard__main">
      <ScoreboardCompetitor
        side="HOME"
        :sport-id="event.sportId"
        :team-in-possession="teamInPossession"
        :name="homeName"
        :reverse="isUsaView"
        :in-possession="isHomeInPossession"
        :attack-length-in-seconds="attackLengthInSeconds"
        :seconds-left-in-attack="secondsLeftInAttack"
        :foul-count-in-period="homeFoulCountInPeriod"
        :foul-threshold-for-bonus="foulThresholdForBonus"
        :shot-clock-icon="event.sportId"
        :timeouts-left="event?.home?.timeoutsLeft"
        :period="event.period"
        :is-live="event.matchState === 'LIVE'"
      />
      <div class="scoreboard__info">
        <ScoreboardTimer
          :variant="matchState"
          :period-label="periodLabel"
          :period-progress="periodProgress"
        />
        <div
          :class="[
            'scoreboard__score',
            { 'scoreboard__score--reverse': isUsaView }
          ]"
        >
          <span>{{ homeScore }}</span>
          &minus;
          <span>{{ awayScore }}</span>
        </div>
      </div>
      <ScoreboardCompetitor
        side="AWAY"
        :sport-id="event.sportId"
        :team-in-possession="teamInPossession"
        :name="awayName"
        :reverse="!isUsaView"
        :in-possession="isAwayInPossession"
        :attack-length-in-seconds="attackLengthInSeconds"
        :seconds-left-in-attack="secondsLeftInAttack"
        :foul-count-in-period="awayFoulCountInPeriod"
        :foul-threshold-for-bonus="foulThresholdForBonus"
        :shot-clock-icon="event.sportId"
        :timeouts-left="event?.away?.timeoutsLeft"
        :period="event.period"
        :is-live="event.matchState === 'LIVE'"
      />
    </div>
    <BaseballIndicators
      v-if="isBaseball"
      :balls="event.balls"
      :strikes="event.strikes"
      :outs="event.outs"
      :base1="event.base1"
      :base2="event.base2"
      :base3="event.base3"
    />
    <div
      v-if="isBaseball"
      class="scoreboard__baseball-meta"
    >
      <div class="scoreboard__baseball-meta-group">
        <div class="scoreboard__baseball-meta-label">
          <Icon name="pitcher" />
          Pitcher
        </div>
        <div
          class="scoreboard__baseball-meta-value"
          :title="pitcherName"
        >
          {{ pitcherName }}
        </div>
      </div>
      <div class="scoreboard__baseball-meta-group">
        <div class="scoreboard__baseball-meta-label">
          Pitch Count
        </div>
        <div class="scoreboard__baseball-meta-value">
          {{ pitchCount }}
        </div>
      </div>
      <div class="scoreboard__baseball-meta-group">
        <div class="scoreboard__baseball-meta-label">
          Batting Order
        </div>
        <div class="scoreboard__baseball-meta-value">
          {{ timesThrough }}
        </div>
      </div>
      <div class="scoreboard__baseball-meta-group">
        <div class="scoreboard__baseball-meta-label">
          <Icon name="hitter" />
          Hitter
        </div>
        <div
          class="scoreboard__baseball-meta-value"
          :title="hitterName"
        >
          {{ hitterName }}
        </div>
      </div>
    </div>
    <div
      v-if="isFootball"
      class="scoreboard__football-meta"
    >
      <div class="scoreboard__football-meta-group">
        <div class="scoreboard__football-meta-label">
          Down
        </div>
        <div class="scoreboard__football-meta-value">
          {{ footballDownValue }}
        </div>
      </div>
      <div class="scoreboard__football-meta-group">
        <div class="scoreboard__football-meta-label">
          To go
        </div>
        <div class="scoreboard__football-meta-value">
          {{ footballToGoValue }}
        </div>
      </div>
      <div class="scoreboard__football-meta-group">
        <div class="scoreboard__football-meta-label">
          On
        </div>
        <div class="scoreboard__football-meta-value">
          {{ footballOnValue }}
        </div>
      </div>
      <div class="scoreboard__football-meta-group">
        <div class="scoreboard__football-meta-label">
          Play running
        </div>
        <div class="scoreboard__football-meta-value">
          <BooleanIndicator
            v-if="footballPlayValueAvailable"
            :value="footballPlayValue"
          />
          <span v-else>
            -
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { includes, find, isNil } from 'lodash';
import { computed } from 'vue';
import { differenceInDays, startOfDay, format } from 'date-fns';
import { zonedTimeToUtc } from 'date-fns-tz';
import { useSquad } from '@/composables';
import sportIds from '@/services/helpers/sports';
import Event from '@/services/helpers/event';
import Soccer from '@/services/helpers/soccer';
import Basketball from '@/services/helpers/basketball';
import Football from '@/services/helpers/football';
import Ultimate from '@/services/helpers/ultimate';
import Hockey from '@/services/helpers/hockey';
import Baseball from '@/services/helpers/baseball';
import Icon from '@/components/common/Icon';
import BaseballIndicators from '@/components/common/BaseballIndicators';
import BooleanIndicator from '@/components/common/BooleanIndicator';
import ScoreboardCompetitor from './ScoreboardCompetitor';
import ScoreboardTimer from './ScoreboardTimer';

const {
  BASKETBALL_ID,
  FOOTBALL_ID,
  SOCCER_ID,
  ULTIMATE_ID,
  HOCKEY_ID,
  BASEBALL_ID,
} = sportIds;

export default {
  components: {
    Icon,
    BaseballIndicators,
    BooleanIndicator,
    ScoreboardCompetitor,
    ScoreboardTimer,
  },
  props: {
    event: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const sportUtils = computed(() => {
      switch (props.event.sportId) {
      case SOCCER_ID:
        return Soccer;
      case BASKETBALL_ID:
        return Basketball;
      case FOOTBALL_ID:
        return Football;
      case ULTIMATE_ID:
        return Ultimate;
      case HOCKEY_ID:
        return Hockey;
      case BASEBALL_ID:
        return Baseball;
      default:
        return null;
      }
    });
    const matchState = computed(() => props.event.matchState);
    const livePeriods = computed(() => sportUtils.value?.getLivePeriods(props.event) || []);
    const periodLabel = computed(() => {
      if (Event.isCanceled(props.event)) return 'Canceled';
      if (Event.isFinished(props.event)) return 'Finished';
      if (Event.isLive(props.event) && !includes(livePeriods.value, props.event.period)) return 'Live';
      if (!Event.isPrematch(props.event)) {
        return sportUtils.value.formatPeriod(props.event, {
          formatLabel: sportUtils.value.formatPeriodAbbreviation,
          formatTime: sportUtils.value.formatPeriodTime,
          separator: '•',
        });
      }

      const zonedTime = zonedTimeToUtc(props.event.startsAt, 'UTC');
      const daysUntilMatch = differenceInDays(startOfDay(zonedTime), startOfDay(new Date()));
      if (daysUntilMatch < 0) return 'In 0 days';

      const daysVariant = daysUntilMatch === 1 ? 'day' : 'days';
      const otherDayLabel = `In ${daysUntilMatch} ${daysVariant}`;

      const timestamp = format(zonedTimeToUtc(props.event.startsAt, 'UTC'), 'hh:mm a');
      const todayLabel = `Today at ${timestamp}`;

      return daysUntilMatch ? otherDayLabel : todayLabel;
    });
    const periodProgress = computed(() => sportUtils.value.calculatePeriodProgress(props.event));
    const foulThresholdForBonus = computed(() => props.event.foulThresholdForBonus);
    const isUsaView = computed(() => props.event.isUSAView);
    const attackLengthInSeconds = computed(() => 24);
    const secondsLeftInAttack = computed(() => 24);
    const scoreboardIcon = computed(() => {
      switch (props.event.sportId) {
      case FOOTBALL_ID:
        return 'scoreboard-football';
      case BASEBALL_ID:
        return 'scoreboard-basketball';
      default:
        return '';
      }
    });

    const teamInPossession = computed(() => (props.event?.matchState === 'LIVE' ? (props.event?.teamInPossession || '') : ''));

    const homeName = computed(() => props.event.home.name);
    const homeScore = computed(() => props.event.home.score);
    const homeFoulCountInPeriod = computed(() => props.event.home.foulsPerPeriod?.[props.event.period] ?? 0);
    const isHomeInPossession = computed(() => teamInPossession.value === 'HOME');

    const awayName = computed(() => props.event.away.name);
    const awayScore = computed(() => props.event.away.score);
    const awayFoulCountInPeriod = computed(() => props.event.away.foulsPerPeriod?.[props.event.period] ?? 0);
    const isAwayInPossession = computed(() => teamInPossession.value === 'AWAY');

    const isBaseball = computed(() => props.event?.sportId === BASEBALL_ID);
    const isFootball = computed(() => props.event?.sportId === FOOTBALL_ID);

    const { squad } = useSquad(props.event);
    const pitcherName = computed(() => {
      const player = find(
        squad.value || [],
        ({ playerIndex }) => props.event?.currentPitcher?.index === playerIndex,
      );
      return player?.shortName || '-';
    });
    const pitchCount = computed(() => {
      const value = props.event?.currentPitcher?.stats?.pitchCount;
      return isNil(value) ? '-' : value;
    });
    const timesThrough = computed(() => {
      const value = props.event?.currentPitcher?.stats?.timesThrough;
      return isNil(value) ? '-' : value;
    });
    const hitterName = computed(() => {
      const player = find(
        squad.value || [],
        ({ playerIndex }) => props.event?.currentHitter?.index === playerIndex,
      );
      return player?.shortName || '-';
    });

    const footballDownValue = computed(() => (isNil(props.event.downNumber) ? '-' : props.event.downNumber));
    const footballToGoValue = computed(() => (isNil(props.event.yardsToWinDown) ? '-' : props.event.yardsToWinDown));
    const footballOnValue = computed(() => (isNil(props.event.yardsToEndZone) ? '-' : props.event.yardsToEndZone));
    const footballPlayValueAvailable = computed(() => !isNil(props.event.isPlayRunning));
    const footballPlayValue = computed(() => !!props.event.isPlayRunning);

    return {
      matchState,
      periodLabel,
      periodProgress,
      foulThresholdForBonus,
      isUsaView,
      attackLengthInSeconds,
      secondsLeftInAttack,
      scoreboardIcon,
      teamInPossession,
      homeName,
      homeScore,
      homeFoulCountInPeriod,
      isHomeInPossession,
      awayName,
      awayScore,
      awayFoulCountInPeriod,
      isAwayInPossession,
      isBaseball,
      isFootball,
      pitcherName,
      pitchCount,
      timesThrough,
      hitterName,
      footballDownValue,
      footballToGoValue,
      footballOnValue,
      footballPlayValueAvailable,
      footballPlayValue,
    };
  },
};
</script>

<style lang="scss" scoped>
.scoreboard {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 8px;

  &__main {
    display: flex;
    align-items: flex-start;
    gap: 20px;
    width: 100%;
  }

  &.scoreboard--reverse {
    .scoreboard__main {
      flex-direction: row-reverse;
    }
  }

  .scoreboard__info {
    flex-shrink: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: #191414;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    min-width: 80px;
    gap: 4px;
  }

  .scoreboard__score {
    display: flex;
    gap: 4px;

    &.scoreboard__score--reverse {
      flex-direction: row-reverse;
    }
  }

  .baseball-indicators {
    padding-bottom: 0;
  }

  .scoreboard__baseball-meta {
    display: grid;
    justify-content: space-around;
    grid-template-columns: 76px 76px 76px 76px;
    column-gap: 16px;
    padding: 8px 12px;
    width: 100%;
    max-width: 483px;
    margin: 0 auto;
    overflow-x: auto;

    &-group {
      display: flex;
      flex-direction: column;
      gap: 4px;
    }

    &-label {
      display: flex;
      align-items: center;
      color: #6D6D6D;
      font-family: 'Rubik', sans-serif;
      font-weight: 400;
      font-size: 10px;
      line-height: 11.85px;
      text-transform: uppercase;
      gap: 4px;

      .icon {
        width: 12px;
        height: 12px;
        min-width: 12px;
        min-height: 12px;
        max-width: 12px;
        max-height: 12px;
      }
    }

    &-value {
      color: #191414;
      font-family: 'Rubik', sans-serif;
      font-weight: 400;
      font-size: 14px;
      line-height: 16px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }

  .scoreboard__football-meta {
    display: grid;
    justify-content: space-around;
    grid-template-columns: 76px 76px 76px 76px;
    column-gap: 16px;
    padding: 8px 12px;
    width: 100%;
    max-width: 400px;
    margin: 0 auto;
    overflow-x: auto;

    &-group {
      display: flex;
      flex-direction: column;
      gap: 4px;
    }

    &-label {
      display: flex;
      align-items: center;
      color: #6D6D6D;
      font-family: 'Rubik', sans-serif;
      font-weight: 400;
      font-size: 10px;
      line-height: 11.85px;
      text-transform: uppercase;
      gap: 4px;

      .icon {
        width: 12px;
        height: 12px;
        min-width: 12px;
        min-height: 12px;
        max-width: 12px;
        max-height: 12px;
      }
    }

    &-value {
      color: #191414;
      font-family: 'Rubik', sans-serif;
      font-weight: 400;
      font-size: 14px;
      line-height: 16px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
}
</style>
