<template>
  <div
    :class="[
      'scoreboard-timer',
      { 'scoreboard-timer--prematch': variant === 'PREMATCH' },
      { 'scoreboard-timer--live': variant === 'LIVE' },
      { 'scoreboard-timer--finished': variant === 'FINISHED' },
      { 'scoreboard-timer--finished': variant === 'CANCELED' },
    ]"
  >
    <div class="scoreboard-timer__label">
      {{ periodLabel }}
    </div>
    <div
      v-if="showPeriodProgress"
      class="scoreboard-timer__progress"
    >
      <span
        class="scoreboard-timer__progress-bar"
        :style="`width: ${periodProgress}%`"
      />
    </div>
  </div>
</template>

<script>
import { computed } from 'vue';

export default {
  props: {
    variant: {
      type: String,
      default: 'PREMATCH',
    },
    periodLabel: {
      type: String,
      default: 'N/A',
    },
    periodProgress: {
      type: Number,
      default: 0,
    },
  },
  setup(props) {
    const showPeriodProgress = computed(() => props.variant === 'LIVE' && props.periodProgress >= 0);

    return {
      showPeriodProgress,
    };
  },
};
</script>

<style lang="scss" scoped>
.scoreboard-timer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  overflow: hidden;
  padding: 4px 8px;
  position: relative;

  &.scoreboard-timer--prematch {
    background-color: #E8EFFF;
    color: #114CCC;
  }

  &.scoreboard-timer--live {
    background-color: #002611;
    color: #99E4BB;
  }

  &.scoreboard-timer--finished {
    background-color: #191414;
    color: #fff;
  }

  &.scoreboard-timer--canceled {
    background-color: #191414;
    color: #fff;
  }
}

.scoreboard-timer__label {
  font-family: Rubik;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.scoreboard-timer__progress {
  background-color: #E5F7EE;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 3px;
}

.scoreboard-timer__progress-bar {
  display: block;
  background-color: #99E4BB;
  height: 100%;
}
</style>
