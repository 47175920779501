<template>
  <div class="drive-outcome-probabilities">
    <div class="drive-outcome-probabilities__header">
      <Heading
        as="h2"
        size="xs"
      >
        Drive Outcome Probabilities
      </Heading>
    </div>
    <div class="drive-outcome-probabilities__content">
      <Spinner
        v-if="loading"
        class="drive-outcome-probabilities__spinner"
      />
      <DriveOutcomeProbabilitiesTable
        v-else
        :columns="columns"
        :probabilities="probabilities || {}"
      />
    </div>
  </div>
</template>

<script>
import {
  find,
} from 'lodash';
import { ref, computed } from 'vue';
import { useStore } from 'vuex';
import Heading from '@/components/common/Heading';
import Spinner from '@/components/common/Spinner';
import { createDriveOutcomeProbabilitiesColumns } from '@/services/helpers/football';
import DriveOutcomeProbabilitiesTable from './DriveOutcomeProbabilitiesTable';

export default {
  components: {
    Heading,
    Spinner,
    DriveOutcomeProbabilitiesTable,
  },
  setup() {
    const loading = ref(false);
    const store = useStore();
    const tradingEvent = computed(() => store.getters.tradingEvent);
    const probabilities = computed(() => find(tradingEvent.value?.pricingProbabilitiesByEventId?.nodes, (item) => item.eventId === tradingEvent.value.eventId)?.probabilities);

    const columns = computed(() => createDriveOutcomeProbabilitiesColumns());

    return {
      loading,
      columns,
      probabilities,
    };
  },
};
</script>

<style lang="scss" scoped>
.drive-outcome-probabilities {
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.drive-outcome-probabilities__header {
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-bottom: 8px;
}

.drive-outcome-probabilities__title {
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  color: #191414;
}

.drive-outcome-probabilities__content {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.drive-outcome-probabilities__spinner {
}

.drive-outcome-probabilities__baseball-navigation {
  height: 33px;
  width: 100%;
  box-shadow: inset 0px -2px 0px #CDCDCD;
  display: flex;

  .drive-outcome-probabilities__baseball-navigation-tab {
    flex: 1;
    width: 100%;
    height: 100%;
    padding: 0 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    text-align: center;

    &.selected {
      box-shadow: inset 0px -2px 0px #003C3C;
    }
  }
}
</style>
