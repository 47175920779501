<template>
  <div class="players-tabs">
    <div
      v-for="item in items"
      :key="item.id"
      :class="[
        'players-tabs__tab',
        { 'players-tabs__tab--active': isItemSelected(item) },
      ]"
      @click="selectItem(item)"
    >
      <div class="players-tabs__tab-label">
        {{ item.label }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    modelValue: {
      type: String,
      required: true,
    },
    items: {
      type: Array,
      required: true,
    },
  },
  emits: {
    'update:modelValue': {
      type: String,
    },
  },
  setup(props, { emit }) {
    const isItemSelected = ({ id }) => props.modelValue === id;
    const selectItem = ({ id }) => {
      emit('update:modelValue', id);
    };

    return {
      isItemSelected,
      selectItem,
    };
  },
};
</script>

<style lang="scss" scoped>
.players-tabs {
  display: flex;
  user-select: none;
}

.players-tabs__tab {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 8px;
  height: 33px;
  border-bottom: 2px solid #CDCDCD;
  overflow: hidden;
  cursor: pointer;

  &.players-tabs__tab--active {
    border-color: #003C3C;
  }
}

.players-tabs__tab-label {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
