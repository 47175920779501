<template>
  <div class="drive-outcome-probabilities-table">
    <div class="drive-outcome-probabilities-table__content">
      <div
        :class="[
          'drive-outcome-probabilities-table__row',
          'drive-outcome-probabilities-table__row--header',
        ]"
      >
        <div
          v-for="(column, id) in columns"
          :key="id"
          :title="column.tooltip"
          :style="getCellStyle(column)"
          class="drive-outcome-probabilities-table__cell"
        >
          {{ column.label }}
        </div>
      </div>
      <div
        :class="[
          'drive-outcome-probabilities-table__row',
          'drive-outcome-probabilities-table__row--data',
        ]"
      >
        <div
          v-for="(column, id) in columns"
          :key="id"
          :style="getCellStyle(column)"
          class="drive-outcome-probabilities-table__cell"
        >
          {{ getCellValue(column) }}
        </div>
      </div>
      <div
        v-if="showMessage"
        class="drive-outcome-probabilities-table__message"
      >
        No data available.
      </div>
    </div>
  </div>
</template>

<script>
import { get } from 'lodash';
import { computed } from 'vue';

export default {
  props: {
    columns: {
      type: Object,
      required: true,
    },
    probabilities: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const showHeader = computed(() => props.probabilities);
    const showMessage = computed(() => !props.probabilities);

    const getCellStyle = (column) => column.style || {};
    const getCellValue = (column) => {
      let value = get(props.probabilities, column.field, '-');
      if (value !== '-') {
        value *= 100;
        value = Number.isInteger(value) ? value : parseFloat(value.toFixed(2));
        if (column.showPercentage) {
          value = `${value}%`;
        }
      }

      return value;
    };

    return {
      showHeader,
      showMessage,
      getCellStyle,
      getCellValue,
    };
  },
};
</script>

<style lang="scss" scoped>
.drive-outcome-probabilities-table {
  width: 100%;
  height: 100%;
  overflow: auto;
}

.drive-outcome-probabilities-table__content {
  display: table;
  border-collapse: collapse;
  position: relative;
  width: 100%;
}

.drive-outcome-probabilities-table__row {
  display: table-row;
  width: 100%;

  &.drive-outcome-probabilities-table__row--header {
    background-color: #fafafa;
    color: #6D6D6D;
    height: 33px;

    .drive-outcome-probabilities-table__cell {
      position: sticky;
      top: 0;
      z-index: 1;

    }
  }

  &.drive-outcome-probabilities-table__row--data {
    background-color: #fff;
    color: #191414;
    border-bottom: 1px solid #F0F0F0;
    height: 44px;
  }
}

.drive-outcome-probabilities-table__cell {
  background-color: inherit;
  color: inherit;
  display: table-cell;
  vertical-align: middle;
  height: 100%;
  min-width: 50px;
  padding: 8px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.drive-outcome-probabilities-table__message {
  font-family: Rubik;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  color: #A9A9A9;
  text-align: center;
  width: 100%;
}
</style>
