<template>
  <div
    ref="rootElement"
    class="statistics-select"
  >
    <div
      :class="[
        'statistics-select__head',
        { 'statistics-select__head--active': isOpen },
      ]"
      @click="toggle"
    >
      {{ selectedDisplayValue }}
      <Icon
        name="chevron-down"
      />
    </div>
    <div
      v-show="isOpen"
      class="statistics-select__dropdown"
    >
      <div
        v-for="item in items"
        :key="item.id"
        class="statistics-select__dropdown-item"
        @click="updateValue(item)"
      >
        {{ item.label }}
      </div>
    </div>
  </div>
</template>

<script>
import { find } from 'lodash';
import { ref, computed } from 'vue';
import { onClickOutside } from '@vueuse/core';
import Icon from '@/components/common/Icon';

export default {
  components: {
    Icon,
  },
  props: {
    value: {
      type: String,
      required: true,
    },
    items: {
      type: Array,
      required: true,
    },
  },
  emits: {
    'update:value': {
      type: String,
    },
  },
  setup(props, { emit }) {
    const rootElement = ref(null);
    const isOpen = ref(false);
    const selectedDisplayValue = computed(() => find(props.items, { id: props.value })?.label ?? 'N/A');

    const toggle = () => {
      isOpen.value = !isOpen.value;
    };
    const close = () => {
      isOpen.value = false;
    };
    const updateValue = ({ id }) => {
      emit('update:value', id);
      close();
    };

    onClickOutside(rootElement, close);

    return {
      rootElement,
      isOpen,
      selectedDisplayValue,
      toggle,
      updateValue,
    };
  },
};
</script>

<style lang="scss" scoped>
.statistics-select {
  position: relative;
  height: 33px;
  user-select: none;
}

.statistics-select__head {
  font-family: Rubik;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 4px;
  padding: 0 8px;
  height: 100%;
  gap: 8px;
  cursor: pointer;
  border-radius: 4px;
  border: 1px solid #DDD;
  background: #FFF;
  color: #191414;
  stroke: #191414;

  &:hover {
    border-color: #CDCDCD;
  }
}

.statistics-select__dropdown {
  position: absolute;
  top: calc(100% + 4px);
  right: 0;
  background-color: #fff;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.08);
  border-radius: 4px;
  margin-bottom: 4px;
  border: 1px solid #F0F0F0;
  min-width: 100%;
  z-index: 1000;
}

.statistics-select__dropdown-item {
  display: flex;
  align-items: center;
  white-space: nowrap;
  background-color: #fff;
  padding: 8px;
  height: 44px;
  cursor: pointer;

  &:hover {
    background-color: #FAFAFA;
  }
}
</style>
