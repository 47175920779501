<template>
  <div
    :class="[
      'scoreboard-competitor',
      { 'scoreboard-competitor--reverse': reverse },
    ]"
  >
    <BaseballClock
      v-if="isBaseball"
      :side="side"
      :team-in-possession="teamInPossession"
    />
    <ShotClock
      v-else
      :percentage="shotClockPercentage"
      :icon="shotClockIcon"
      :is-active="shotClockActive"
    />
    <div class="scoreboard-competitor__box">
      <div
        class="scoreboard-competitor__name"
        :title="name"
      >
        {{ name }}
      </div>
      <div
        v-if="foulDisplaySupported"
        :class="[
          'scoreboard-competitor__fouls',
          { 'scoreboard-competitor__fouls--reversed': reverse },
        ]"
      >
        <span
          v-for="foulSlot in foulThresholdForBonus"
          :key="foulSlot"
          :class="[
            'scoreboard-competitor__foul',
            { 'scoreboard-competitor__foul--active': isFoulSlotActive(foulSlot) },
          ]"
        />
      </div>
    </div>
    <FootballTimeoutIndicators
      v-if="isLive && isFootball"
      :period="period"
      :timeouts-left="timeoutsLeft"
      :is-reversed="reverse"
    />
  </div>
</template>

<script>
import { computed } from 'vue';
import sportIds from '@/services/helpers/sports';
import BaseballClock from '@/components/common/BaseballClock';
import ShotClock from '@/components/common/ShotClock';
import FootballTimeoutIndicators from '@/components/common/FootballTimeoutIndicators';

const { FOOTBALL_ID, BASEBALL_ID } = sportIds;

export default {
  components: {
    BaseballClock,
    ShotClock,
    FootballTimeoutIndicators,
  },
  props: {
    name: {
      type: String,
      required: true,
    },
    side: {
      type: String,
      required: true,
    },
    sportId: {
      type: String,
      required: true,
    },
    teamInPossession: {
      type: String,
      required: true,
    },
    reverse: {
      type: Boolean,
      default: false,
    },
    inPossession: {
      type: Boolean,
      default: false,
    },
    secondsLeftInAttack: {
      type: Number,
      default: 0,
    },
    attackLengthInSeconds: {
      type: Number,
      default: 0,
    },
    foulCountInPeriod: {
      type: Number,
      default: 0,
    },
    foulThresholdForBonus: {
      type: Number,
      default: 0,
    },
    shotClockIcon: {
      type: String,
      default: '',
    },
    timeoutsLeft: {
      type: Number,
      default: 0,
    },
    isLive: {
      type: Boolean,
      default: false,
    },
    period: {
      type: String,
      default: '',
    },
  },
  setup(props) {
    const isFootball = computed(() => props.sportId === FOOTBALL_ID);
    const isBaseball = computed(() => props.sportId === BASEBALL_ID);
    const shotClockActive = computed(() => props.inPossession);
    const shotClockPercentage = computed(() => Math.round((props.secondsLeftInAttack / props.attackLengthInSeconds) * 100));

    const foulDisplaySupported = computed(() => !!props.foulThresholdForBonus);
    const isFoulSlotActive = (foulSlot) => foulSlot <= props.foulCountInPeriod;

    return {
      isFootball,
      isBaseball,
      shotClockActive,
      shotClockPercentage,
      foulDisplaySupported,
      isFoulSlotActive,
    };
  },
};
</script>

<style lang="scss">
.scoreboard-competitor {
  display: grid;
  grid-template-columns: auto 1fr;
  column-gap: 8px;
  width: 100%;
  overflow: hidden;

  .football-timeout-indicators {
    grid-column: 2 / 2;
    justify-content: flex-start;
  }

  &.scoreboard-competitor--reverse {
    grid-template-columns: 1fr auto;
    text-align: right;

    .football-timeout-indicators {
      grid-column: 1 / 2;
      grid-row: 2;
    }

    .scoreboard-competitor__box {
      grid-column: 1 / 2;
      grid-row: 1;
    }

    .shot-clock {
      grid-column: 2 / 2;
      grid-row: 1;
    }
  }
}

.scoreboard-competitor__box {
  display: flex;
  flex-direction: column;
  gap: 4px;
  overflow: hidden;
  width: 100%;
}

.scoreboard-competitor__name {
  color: #191414;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  width: 100%;
}

.scoreboard-competitor__fouls {
  display: flex;
  gap: 4px;

  &.scoreboard-competitor__fouls--reversed {
    flex-direction: row-reverse;
  }
}

.scoreboard-competitor__foul {
  width: 8px;
  height: 8px;
  border: 2px solid #191414;
  border-radius: 100%;

  &.scoreboard-competitor__foul--active {
    background-color: #191414;
  }
}
</style>
