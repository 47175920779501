<template>
  <div class="players-table">
    <div class="players-table__content">
      <div
        v-if="showHeader"
        :class="[
          'players-table__row',
          'players-table__row--header',
        ]"
      >
        <div
          v-for="(column, id) in columns"
          :key="id"
          :title="column.tooltip"
          :style="getCellStyle(column)"
          class="players-table__cell"
        >
          {{ column.label }}
        </div>
      </div>
      <div
        v-for="player in players"
        :key="player.id"
        :class="[
          'players-table__row',
          'players-table__row--data',
        ]"
      >
        <div
          v-for="(column, id) in columns"
          :key="id"
          :style="getCellStyle(column)"
          class="players-table__cell"
        >
          {{ getCellValue(column, player) }}
        </div>
      </div>
      <div
        v-if="showMessage"
        class="players-table__message"
      >
        No data available.
      </div>
    </div>
  </div>
</template>

<script>
import { get } from 'lodash';
import { computed } from 'vue';
import { formatMinutesAndSeconds } from '@/utils/number';

export default {
  props: {
    columns: {
      type: Object,
      required: true,
    },
    players: {
      type: Array,
      default: () => [],
    },
  },
  setup(props) {
    const showHeader = computed(() => props.players?.length);
    const showMessage = computed(() => !props.players?.length);

    const getCellStyle = (column) => column.style || {};
    const getCellValue = (column, player) => {
      const defaultValue = column.defaultValue ?? '-';
      let value = get(player, column.field, defaultValue);
      if (column.id === 'minutesPlayed' && value !== '-') {
        value = formatMinutesAndSeconds(value * 60);
      }
      return value || defaultValue;
    };

    return {
      showHeader,
      showMessage,
      getCellStyle,
      getCellValue,
    };
  },
};
</script>

<style lang="scss" scoped>
.players-table {
  width: 100%;
  height: 100%;
  overflow: auto;
}

.players-table__content {
  display: table;
  border-collapse: collapse;
  position: relative;
  width: 100%;
}

.players-table__row {
  display: table-row;
  width: 100%;

  &.players-table__row--header {
    background-color: #fafafa;
    color: #6D6D6D;
    height: 33px;

    .players-table__cell {
      position: sticky;
      top: 0;
      z-index: 1;

      &:first-of-type {
        left: 0;
        z-index: 2;
      }
    }
  }

  &.players-table__row--data {
    background-color: #fff;
    color: #191414;
    border-bottom: 1px solid #F0F0F0;
    height: 44px;

    .players-table__cell:first-of-type {
      position: sticky;
      left: 0;
    }
  }
}

.players-table__cell {
  background-color: inherit;
  color: inherit;
  display: table-cell;
  vertical-align: middle;
  height: 100%;
  min-width: 50px;
  padding: 8px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.players-table__message {
  font-family: Rubik;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  color: #A9A9A9;
  text-align: center;
  width: 100%;
}
</style>
